<template>
	<div class="container-modal form-modal large" >
		<div class="container-holder" v-if="loaded">
			<div class="info-content">
				<div class="header-modal">
					<div class="icon"><icon-cart /></div>
					<div class="title" v-html="$t('register.title_2')"></div>
				</div>
				<div class="container-content-modal" v-if="bundleData.applications.length">
					<div class="card-pack" :class="{'disabled': app.active_subscription && !app.active_subscription_details.grace_period }" v-for="app in bundleData.applications">
						<div class="pack-content">
							<div class="icon">
								<icon-check/>
							</div>
							<div class="pack-details">
								<div class="pack-name">
									{{ app.application_name }}
								</div>
								<div class="pack-type">
									{{ `${$t('register.subscription')}: ${ app.package_name == 'Free' ? 'Gratuit' : app.package_name }` }}
								</div>
							</div>
							<div class="pack-price">
								<div class="price">
									<span class="old-price" v-if="app.old_price_in_euro != app.price_in_euro">{{ app.old_price_in_euro }}€</span>
									<span>{{ `${app.price_in_euro}€` }}</span><span class="monthly">{{ app.interval == 'year' ? $t('subscription.yearly') : $t('subscription.monthly') }}</span> 
								</div>
								<div class="TVA">
									{{ $t('subscription.tva') }}
								</div>
							</div>
						</div>
						<div class="description" v-if="freePackages && app.has_package">
							<span v-if="app.active_subscription_details">
								{{ $t('subscription.can_not_change', {oldPlan: app.active_subscription_details.package_name == 'Free' ? 'Gratuit' : app.active_subscription_details.package_name, newPlan: app.package_name == 'Free' ? 'Gratuit' : app.package_name}) }}
							</span>
							<span v-else>
								{{ $t('subscription.can_not_change_simple', {newPlan: app.package_name == 'Free' ? 'Gratuit' : app.package_name}) }}
							</span>
						</div>
						<div class="description" v-else-if="app.active_subscription && !app.active_subscription_details.grace_period">
							<span v-if="app.active_subscription_details.package_id == app.package_id">
								{{ $t('subscription.has_same_plan', {oldPlan: app.active_subscription_details.package_name == 'Free' ? 'Gratuit' : app.active_subscription_details.package_name}) }}
							</span>
							<span v-else-if="app.active_subscription_details.package_id != app.package_id">
								{{ $t('subscription.plan_will_change', {oldPlan: app.active_subscription_details.package_name == 'Free' ? 'Gratuit' : app.active_subscription_details.package_name, newPlan: app.package_name == 'Free' ? 'Gratuit' : app.package_name}) }}
							</span>
						</div>
					</div>


					<div class="submit-footer" v-if="can_continue_checkout">
						<div class="total-price" v-if="bundleData.applications.length">
							<span class="total">{{ $t('subscription.total')}}</span><span class="price">€{{ bundleData.price_in_euro }}</span><span class="tva">{{ bundleData.applications.interval == 'year' ? $t('subscription.yearly') : $t('subscription.monthly') }}{{ $t('subscription.tva') }}</span>
						</div>
						<div v-else>
							<div class="pack-selected" v-html="$t('register.empty_cart')"></div>
						</div>

						<div id="buttonNextStep" class="submit-form" @click="goNextStep">
							<div class="button-submit w-100">
								<div class="loader-spin" v-if="loadingSubmit"></div>
								<div v-else>{{ $t('subscription.next_step') }}</div>
							</div>
						</div>

						<div class="bottom-links">
							<router-link :to="{name: 'pricing'}" v-html="$t('register.choose_other_plan')"></router-link>
						</div>
					</div>
					<div class="submit-footer" v-else>
						<div v-if="needChange == 0">
							{{ freePackages ? $t('subscription.can_not_change_to_free') : $tc('subscription.has_same_plan_summary', bundleData.applications.length) }}
						</div>

						<div class="submit-form" v-if="needChange > 0">
							<button id="buttonChangePlan" class="button-submit w-100" @click="goChangePlan">
								<div class="loader-spin" v-if="loadingSubmit"></div>
								<div v-else>{{$tc('subscription.change_plans', needChange)}}</div>
							</button>
						</div>
						<div class="bottom-links">
							<router-link :to="{name: 'pricing'}" v-html="$t('register.choose_other_plan')"></router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="loader-step" v-else>
			<div class="loader-spin"></div>
		</div>
	</div>
</template>


<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../../assets/animations/tbfdigital_logo.json';

	import IconClose from '../Icons/Close'
	import IconPeople from '../Icons/People'
	import IconInvoice from '../Icons/Invoice'
	import IconSettings from '../Icons/Settings'
	import IconUsers from '../Icons/Users'
	import IconCheck from '../Icons/CircularCheck'
	import IconDiscount from '../Icons/Discount'
	import IconCart from '../Icons/Cart'

	import { required } from 'vuelidate/lib/validators'

	export default {
		data(){
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true,
				allPackages: [],
				loaded: false,
				value_discount: 0,
				validCoupon: '',
				bundleData: '',
				can_continue_checkout: false,
				needChange: 0,
				freePackages: false,
				loadingSubmit: false
			}
		},
		props:{
			user: Object
		},
		components: {
			IconClose,
			IconPeople,
			IconInvoice,
			IconSettings,
			IconUsers,
			IconCheck,
			IconDiscount,
			Lottie,
			IconCart
		},
		validations: {
			selectedApps: {required: false}
		},
		async mounted() {
			this.user.need_instance = true

			await this.getPackages()
			this.validCoupon = this.user.coupon ? this.user.coupon : ''
		},
		methods: {
			async getPackages(){
				let paramsRequest = {
					year_subscription: this.$cookies.get('optionsRegisterUser').year_subscription ? true : false,
					type: this.$cookies.get('optionsRegisterUser').type,
					name: this.$cookies.get('optionsRegisterUser').name,
					reducere: this.$cookies.get('optionsRegisterUser').discount,
				}
				
				if(this.$cookies.get('optionsRegisterUser').type == 'Single'){
					paramsRequest.package_id = this.$cookies.get('optionsRegisterUser').package
				}

				paramsRequest.from_external = true;

				await axios.get('packages', {params: paramsRequest })
				.then(({data}) => {
					this.bundleData = data.data
					this.freePackages = this.bundleData.price_in_euro == 0 ? true : false;

					this.can_continue_checkout = this.bundleData.applications.find(el => el.active_subscription == false || (el.active_subscription && el.active_subscription_details.grace_period)) ? true : false

					this.bundleData.applications.map(el => {
						if(el.active_subscription && !el.active_subscription_details.grace_period){
							this.bundleData.price_in_euro -= el.price_in_euro
							this.bundleData.price_in_euro_with_vat -= el.price_in_euro_with_vat
							this.bundleData.price_in_ron_with_vat -= el.price_in_ron_with_vat

							if(el.active_subscription_details.package_id != el.package_id){
								this.needChange++
							}
						}
					})

					if(this.freePackages) {
						this.needChange = this.bundleData.applications.find(el => !el.has_package) ? this.needChange : 0
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
					}, 0)
				})
			},
			checkCoupon(){
				var buttonName = 'btnCheckCoupon'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.errorCoupon = false
				this.value_discount = 0

				axios.post('sync-validate-coupon', {coupon: this.user.coupon})
				.then(({data}) => {
					if(data.data.find(el => el.response.is_valid)){
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('register.coupon-success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								data.data.map((el) => {
									if(el.response.is_valid){
										this.user.newPrices.push({ application_id: el.application_id, prices: el.response.values })
										this.validCoupon = this.user.coupon
									}
								})

								if(this.bundleData){
									this.bundleData.applications.map(application => {
										var apps_discount_prices = this.user.newPrices.find((el) => el.application_id == application.application_id)
										if(apps_discount_prices && apps_discount_prices.prices.find((el) => el.package_id == application.package_id)){
											this.value_discount += parseFloat(application.price_in_euro) - parseFloat(apps_discount_prices.prices.find((el) => el.package_id == application.package_id).price)
										}
									})
								}

								setTimeout(() => {
									btnSubmitText.innerHTML = this.$t('register.coupon-apply-short')
									btnSubmit.classList.remove('completed')
								}, 500)
							}, 300)
						}, 300)
					}else{
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('register.coupon-error')
							btnSubmit.classList.add('error')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('error')
								btnSubmitText.innerHTML = this.$t('register.coupon-apply-short')
								this.errorCoupon = true
								this.user.newPrices = []
								this.validCoupon = ''
							}, 1000)
						}, 300)
					}
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('register.coupon-error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('register.coupon-apply-short')
							this.errorCoupon = true
							this.user.newPrices = []
							this.validCoupon = ''
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			goNextStep(){
				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true
				this.loadingSubmit = true;

				this.$v.$touch()
				if(this.$v.$invalid){
					btnSubmit.disabled = false;
					this.loadingSubmit = false;
				}else{
					var temporarDataSubmit = []
					var applications_change_plan = []

					this.bundleData.applications.map(el => {
						if(!el.active_subscription || (el.active_subscription && el.active_subscription_details.grace_period)){
							temporarDataSubmit.push({id: el.application_id, package_id: el.package_id})
						}else if(el.package_id != el.active_subscription_details.package_id && !this.freePackages){
							applications_change_plan.push({id: el.application_id, package_token: el.token})
						}
					})
					this.user.packages = {
						selectedApps: this.bundleData,
						dataSubmitSubscriptions: temporarDataSubmit,
						dataApplicationsChange: applications_change_plan,
						value_discount_voucher: this.value_discount,
						total_price_in_euro: this.bundleData.price_in_euro - this.value_discount,
						total_price_in_euro_with_vat: this.bundleData.price_in_euro_with_vat - (this.value_discount ? this.bundleData.price_in_euro_with_vat : 0),
						total_price_in_ron_with_vat: this.bundleData.price_in_ron_with_vat - (this.value_discount ? this.bundleData.price_in_ron_with_vat : 0)
					}

					this.user.freePackages = this.freePackages
					this.user.company_name = this.$auth.user().company_name ? this.$auth.user().company_name : ''

					this.$emit("next_step", {user: this.user, next_step: 3});
				}
			},
			goChangePlan(){
				var applications_change_plan = []

				this.bundleData.applications.map(el => {
					if(!el.active_subscription || (el.active_subscription && el.active_subscription_details.grace_period)){
						//
					}else if(el.package_id != el.active_subscription_details.package_id){
						applications_change_plan.push({id: el.application_id, package_token: el.token})
					}
				})

				var buttonName = 'buttonChangePlan'
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true
				this.loadingSubmit = true;

				axios.post('/sync-payment', {
					applications_change_plan: applications_change_plan
				})
				.then(({data}) => {
					if(data.data[0] && data.data[0].response && data.data[0].response.status == 'error'){
						btnSubmit.disabled = false
					}else{
						this.$router.push({name: 'register-success'})
					}
				})
				.catch(error => {
					this.onPayment = false
					btnSubmit.disabled = false
				})
			}
		}
	}
</script>

<style lang="scss">
	.checkout-template{
		display: flex;
		align-items: flex-start;
		@media (max-width: 780px) {
			flex-direction: column;
		}
		.form-section-register{
			flex: 1;
			padding-right: 30px;
			width: 100%;
			@media (max-width: 780px) {
				padding-right: 0;
				margin-left: 0;
				.input-login-group-tbf.w-tbf-100{
					padding: 0;
				}
			}
		}
		.summary-checkout{
			flex: 0 0 290px;
			max-width: 290px;
			@media (max-width: 780px) {
				max-width: initial;
				width: 100%;
				flex: 0 0 100%;
				margin-top: 30px;
			}
			.title{
				font-weight: 500;
				font-size: 22px;
				color: $primary;
			}
			.box-info{
				margin-top: 15px;
				margin-bottom: 15px;
				border-radius: 10px;
				padding: 15px;
				background: #FCFCFC;
				border: 1px solid #DCDEE1;
				font-size: 14px;
				line-height: 1.4;
				a{
					font-weight: 700;
					color: $primary;
				}
				.item-data{
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 5px;
					.label{
						.soft{
							color: $primary;
							font-size: 14px;
							margin-bottom: 2px;
						}
						.plan{
							color: $placeholderColor;
							font-size: 12px;
						}
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			.data{
				margin-top: 20px;
				.subtitle{
					font-weight: 500;
					font-size: 15px;
					margin-bottom: 10px;
					&.coupon{
						margin-top: 30px;
					}
				}
				.item-data{
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 5px;
					&.package{
						margin-bottom: 10px;
					}
					.label{
						.soft{
							color: $primary;
							font-size: 14px;
							margin-bottom: 2px;
						}
						.plan{
							color: $placeholderColor;
							font-size: 12px;
						}
						.simple-text{
							color: $placeholderColor;
							font-size: 14px;
							line-height: 17px;
						}
						.total{
							color: $primary;
							font-size: 14px;
						}
					}
					.value{
						color: $primary;
						font-size: 14px;
					}
				}
				.coupon-data{
					margin-bottom: 30px;
					.input-box{
						border-radius: 10px;
						border: 1px solid #dcdee1;
						position: relative;
						display: flex;
						&:focus-within {
							box-shadow: inset 0 -1px 5px 0 rgba(255, 255, 255, 0.25);
							border: solid 1px rgb(223, 224, 226);
							background-color: rgb(246, 246, 247);
						}
						input{
							height: 38px;
							padding: 0 15px;
							width: 100%;
							border: 0;
							font-size: 12px;
							color: $primary;
							background: transparent;
							&::-webkit-input-placeholder {color: $placeholderColor;}
							&:-moz-placeholder           {color: $placeholderColor;}
							&::-moz-placeholder          {color: $placeholderColor;}
							&:-ms-input-placeholder      {color: $placeholderColor;}
							&:focus{
								outline: none;
							}
						}
						.btn-tbf{
							border-radius: 10px;
							width: 115px;
							min-width: 115px;
							margin-top: -1px;
							margin-bottom: -1px;
							margin-right: -1px;
							height: 42px;
						}
					}
				}
				.line-total{
					height: 1px;
					width: 100%;
					background: $borderColor;
					margin: 15px 0;
				}
			}
		}
	}

	.checkout-submit{
		align-items: flex-end;
		flex-direction: column;
		.error-msg{
			margin-bottom: 5px;
		}
		.btn-cancel{
			margin-top: 15px;
		}
	}
</style>